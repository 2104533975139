import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import i18nConfig from '../../../../i18n.json';
import nookies from 'nookies';
import { Select, Space } from 'antd';
import { useRouter } from 'next/router';
import { AppCookies } from '@constants/app';
import dynamic from 'next/dynamic';

type Props = {
  className?: string;
}

const Flag = dynamic(import('react-world-flags'));

export const LanguageSwitch: React.FunctionComponent<Props> = ({
  className = '',
}) => {
  const { t, lang } = useTranslation();
  const router = useRouter();
  const onChange = React.useCallback((changeTo: string) => {
    nookies.set(null, AppCookies.NEXT_LOCALE, changeTo, {
      maxAge: 30 * 24 * 3600,
      path: '/',
      sameSite: 'lax',
    });
    router.push(router.asPath, router.asPath, { locale: changeTo });
  }, [lang, router.pathname]);

  return (
    <Select defaultValue={lang} className={className} onChange={onChange}>
      {i18nConfig.locales.map(l => (
        <Select.Option key={l} value={l}>
          <Space direction="horizontal">
            <Flag code={l.split('-').pop()} height={12} />
            <span>{t(`locale:${l}`)}</span>
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};