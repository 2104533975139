import React from 'react';
import Flex from '@react-css/flex';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { Typography, Button } from 'antd';
import { AppPages } from '@constants/app';
import { LanguageSwitch } from '@components/i18n';
const { Title } = Typography;

export const HomePage: React.FC = () => {
  const { t } = useTranslation('public-home');

  return (
    <Flex justifyCenter alignItemsCenter className="m-auto">
      <LanguageSwitch className="absolute top-4 right-4 z-[1001]" />
      <div className="grid p-6 bg-white">
        <Title level={2} className="mt-0">{t('title')}</Title>
        <div className="grid gap-3 grid-cols-2">
          <Link href={AppPages.USER.HOME}>
            <Button type="primary" htmlType="submit">{t('buttons.user-login')}</Button>
          </Link>
          <Link href={AppPages.ADMIN.HOME}>
            <Button type="default" htmlType="submit">{t('buttons.admin-login')}</Button>
          </Link>
        </div>
      </div>
    </Flex>
  );
}